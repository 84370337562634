<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>What is CASE?</h3>

	<p><b>CASE</b> (Competencies and Academic Standards Exchange) is a <b>“standard for standards”:</b> a <a href="https://www.imsglobal.org/activity/case" target="_blank">specification</a> published and maintained by <a href="https://www.1edtech.org/" target="_blank">1EdTech</a>, designed to facilitate the sharing of “competencies” (e.g. learning standards) in a standardized machine readable format across different digital systems. (1EdTech was formerly known as IMS Global.)</p>
	<p>CASE has two parts:</p>
	<ul>
		<li>A specification that describes <b>how to encode</b> competencies, associations between competencies, and competency frameworks (i.e. collections of competencies and associations) in a <b><span v-html="link('case_json', 'standardized JSON format')"></span></b>.</li>
		<li>A specification that describes a set of <b><span v-html="link('case_rest_api', 'REST APIs')"></span></b> that allow a CASE <i>consumer</i> to retrieve CASE-formatted JSON describing competencies, associations, or frameworks from a CASE <i>publisher</i>.</li>
	</ul>
	<p><span v-html="app_name"/> is a 1EdTech-certified CASE publishing tool. Competencies and competency frameworks authored in and/or hosted by <span v-html="app_name"/> are stored in the standard CASE JSON format, and can be retrieved by other systems via the standard CASE APIs.</p>
	<h3>The CASE Ecosystem</h3>
	<p>The image below illustrates how the CASE Ecosystem is designed to work:</p>
	<img srcset="/docimages/case-1.jpg 2x" class="k-help-img block">
	<ul>
		<li><b style="background-color:#DDF39B">Issuing agencies</b> (e.g. the Georgia Department of Education) publish competency frameworks (e.g. state board-approved Georgia learning standards) in CASE format, on a webserver running a CASE publishing tool. Many issuing agencies use instances of Standards Satchel as their CASE publishing tools, but other tools are also available.</li>
		<li><b style="background-color:#F6AC6A">Edtech suppliers</b> can then access the official source of truth for agencies’ competencies via the standardized CASE APIs.</li>
		<li>Since 1EdTech-certified CASE publishing tools are guaranteed to use the standardized CASE format and follow the standard CASE API protocols, suppliers can count on getting consistently-formatted data from each agency. Suppliers can also use the APIs to pull fresh competency data whenever issuing agencies publish updates. And if suppliers go straight to each agency’s CASE publishing tool, access to the agency’s public standards data will be free of charge (as it should be!).</li>
		<li><b style="background-color:#84D6F8">Other agencies</b>, such as school districts, can also pull CASE data from agencies’ CASE publishing tools to, e.g., populate student information and learning management systems (SISs and LMSs) with standards, again at no cost.</li>
		<li>Common Good Learning Tools hosts, in collaboration with 1EdTech, <b style="background-color:#ACCBF4">CASE Network 2</b>, an instance of Standards Satchel that “mirrors” frameworks from all known issuing agencies that publish in CASE format.<ul>
			<li>This provides suppliers with a convenient “one-stop shop” for accessing CASE data, saving them the hassle of going to 50 different API URLs for each of the 50 states’ frameworks.</li>
			<li>CASE Network also includes unofficial (but up-to-date) CASE representations of the current Math, ELA, Science, and Social Studies for states that aren’t yet publishing in CASE format.</li>
			<li>To support the maintenance of CASE Network, edtech suppliers that wish to use for API/JSON download purposes pay a small yearly fee.</li>
			<li>Public institutions (e.g. school districts in states that aren’t yet officially publishing in CASE format) are able to access CASE Network for free.</li>
			<li>And anyone can freely browse the competencies on CASE Network 2 without signing in.</li>
		</ul></li>
		<li>Common Good Learning Tools also hosts <b style="background-color:#BFA4CF">Satchel Commons</b>. Agencies that cannot stand up their own dedicated CASE publishing tool can host their frameworks on a “patch” of the Commons, which CGLT makes available at a minimal cost. These frameworks are available to suppliers and other agencies for free directly from a agency-specific CASE API URL on Satchel Commons, or can be accessed via CASE Network 2.</li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	